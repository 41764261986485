<template>
    <transition appear @enter="onEnter">
        <svg
            id="tomato"
            ref="tomato"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            :width="size"
            :height="size"
            version="1.1"
            style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
            viewBox="0 0 393.34 304.77"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
            class="hero"
        >
            <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <g id="_2586340648032">
                    <g ref="legs">
                        <path
                            ref="legLeft"
                            class="leg leg_left"
                            fill="#283039"
                            fill-rule="nonzero"
                            d="M169.95 231.54c-5.91,16.42 -7.5,34.74 -6.23,52.23 -17.7,-6.49 -37.47,-7.07 -55.97,-4.24 -7.3,1.12 -32.16,3.63 -30.38,16.7 1.35,9.9 15.8,8.63 22.53,8.31 21.58,-1.02 43.84,-4.03 64.57,-10.13 0.97,-0.29 6.25,-2.37 6.43,-4.97 1.36,-19.1 0.31,-37.35 7.18,-55.65 1.9,-5.07 -6.29,-7.34 -8.13,-2.25z"
                        />
                        <path
                            ref="legRight"
                            class="leg leg_right"
                            fill="#283039"
                            fill-rule="nonzero"
                            d="M289.46 267.84c-14.82,0.43 -33.7,2.68 -47.99,10.2 -1.96,-0.01 -3.82,0.08 -5.5,0.31 0.48,-8.14 -0.26,-16.73 -0.21,-24.28 0.06,-6.7 2.2,-18.83 -2.61,-24.22 -1.65,-1.85 -5.29,-1.48 -6.39,0.82 -0.05,0.12 -0.11,0.24 -0.17,0.36 -0.6,1.26 -0.56,2.58 0.01,3.7 2.58,8.35 0.81,20.07 0.91,28.6 0.07,6.17 0.46,26.54 1.74,27.11 0.12,0.41 66.39,7.37 72.71,7.92 8.87,0.77 29.11,0.75 29.33,-12.24 0.32,-17.84 -30.71,-18.61 -41.83,-18.28z"
                        />
                    </g>

                    <path
                        ref="heroBody"
                        class="hero_body"
                        fill="#EE5B3F"
                        fill-rule="nonzero"
                        d="M324.28 120.06c-1.02,-10.5 -4.39,-20.58 -7.92,-30.46 -3.14,-8.79 -6.85,-17.46 -12.09,-25.23 -4.93,-7.31 -11.21,-13.55 -18.55,-18.45 -8.97,-5.98 -19.13,-9.99 -29.43,-13.04 -14.63,-4.32 -29.76,-6.86 -44.86,-8.88 -9.65,-1.3 -19.34,-2.33 -29.04,-3.24l-0.07 0 -0.06 -0.01c-4.12,-0.05 -8.29,0.43 -12.36,1 -9.28,1.31 -18.47,3.71 -27.08,7.44 -10.69,4.63 -20.49,11.45 -27.75,20.63 -4.14,5.24 -7.39,11.14 -9.67,17.41 -2.34,6.4 -3.42,13.08 -4.49,19.77 -1.84,11.49 -3.43,23.03 -4.3,34.63 -0.91,12.07 -1.06,24.25 0.23,36.3 1.26,11.62 3.9,23.19 8.66,33.9 4.85,10.9 11.93,20.7 20.89,28.59 10.5,9.25 23.2,15.67 36.45,19.96 15.93,5.16 32.99,7.86 49.74,7.42 14.51,-0.38 28.96,-3.33 42.29,-9.13 12.03,-5.23 23.02,-12.72 32.42,-21.86 9.02,-8.78 16.57,-19.04 22.49,-30.14 5.68,-10.67 9.89,-22.12 12.39,-33.95 2.26,-10.69 3.17,-21.76 2.11,-32.66z"
                    />
                    <path
                        ref="stalk"
                        class="stalk"
                        fill="#1A5468"
                        fill-rule="nonzero"
                        d="M251.51 21.98c0.05,-0.31 -0.12,-0.59 -0.5,-0.82 -3.92,-2.28 -21.98,-0.31 -25.03,0.05 2.14,-2.41 12.24,-13.91 11.04,-15.63 -0.25,-0.36 -0.63,-0.56 -1.12,-0.6 -4.24,-0.39 -16.68,11.53 -18.37,13.16 -0.75,-1.94 -5.93,-14.94 -10.52,-17.79 -0.64,-0.4 -1.18,-0.46 -1.58,-0.17 -1,0.74 -1.06,3.73 -0.18,8.91 0.54,3.19 1.29,6.28 1.52,7.22 -2.28,-0.84 -16.92,-5.97 -19.81,-3.27 -0.26,0.24 -0.36,0.54 -0.29,0.86 0.27,1.3 3.3,3.19 9.01,5.64 3.5,1.5 6.97,2.76 8.1,3.16 -1.15,0.5 -4.8,2.1 -8.42,3.89 -8.86,4.38 -8.86,5.58 -8.87,5.98 0,0.18 0.09,0.46 0.51,0.65 3.31,1.43 15.88,-3.26 18.4,-4.23 -1.56,2.65 -8.38,14.43 -7.72,16.25 0.06,0.17 0.18,0.25 0.27,0.28l0.14 0.01c2.05,0 11.53,-10.42 13.77,-12.9 -0.34,2.23 -1.43,11.07 1.21,15.83 0.38,0.68 0.77,1.02 1.18,1.02l0.15 -0.02c2.31,-0.46 3.76,-12.9 4.04,-15.55 1.11,1.17 6.05,5.98 14.51,8.43 1.79,0.52 2.82,0.48 3.16,-0.12 1.16,-2.08 -7.62,-10.27 -9.44,-11.93 3.6,-0.8 24.41,-5.57 24.84,-8.31z"
                    />
                    <g ref="eyes">
                        <path
                            ref="eyeLeftWhite"
                            class="eye eye_left white"
                            fill="white"
                            fill-rule="nonzero"
                            d="M193.89 106.38c1.16,11.4 -4.84,21.34 -13.38,22.21 -8.54,0.86 -16.41,-7.68 -17.56,-19.07 -1.15,-11.4 4.83,-21.35 13.38,-22.21 8.54,-0.87 16.41,7.67 17.56,19.07z"
                        />
                        <path
                            ref="eyeRightWhite"
                            class="eye eye_right white"
                            fill="white"
                            fill-rule="nonzero"
                            d="M220.71 106.38c1.16,11.4 -4.83,21.34 -13.38,22.21 -8.54,0.86 -16.4,-7.68 -17.56,-19.07 -1.15,-11.4 4.84,-21.35 13.38,-22.21 8.55,-0.87 16.41,7.67 17.56,19.07z"
                        />
                        <path
                            ref="eyeRight"
                            class="eye eye_right"
                            fill="#282F39"
                            fill-rule="nonzero"
                            d="M204.35 95.63c-13.06,0 -13.08,20.3 0,20.3 13.06,0 13.08,-20.3 0,-20.3z"
                        />
                        <path
                            ref="eyeLeft"
                            class="eye eye_left"
                            fill="#282F39"
                            fill-rule="nonzero"
                            d="M175.93 97.8c-13.06,0 -13.08,20.3 0,20.3 13.06,0 13.08,-20.3 0,-20.3z"
                        />
                        <g ref="brows">
                            <path
                                ref="eyeLeftBrow"
                                class="eye eye_left eyebrow"
                                fill="#282F39"
                                fill-rule="nonzero"
                                d="M174.89 72.18c-2.35,-1.62 -5.62,0.99 -7.6,2.32 -2.36,1.58 -4.87,3.44 -6.21,6.01 -0.79,1.53 0.82,3.21 2.37,2.37 2.32,-1.28 4.26,-3.15 6.56,-4.48 1.95,-1.12 5.22,-1.63 5.74,-4.1 0.19,-0.88 -0.13,-1.62 -0.86,-2.12z"
                            />
                            <path
                                ref="eyeRightBrow"
                                class="eye eye_right eyebrow"
                                fill="#282F39"
                                fill-rule="nonzero"
                                d="M203.97 69.22c-4.13,1.83 -9.33,6.3 -9.31,11.2 0,0.87 1.08,1.64 1.87,1.07 1.7,-1.24 2.98,-2.89 4.49,-4.35 1.62,-1.56 3.48,-2.6 5.49,-3.58 2.94,-1.45 0.37,-5.63 -2.54,-4.34z"
                            />
                        </g>
                    </g>
                    <g ref="arms">
                        <path
                            ref="armLeft"
                            class="arm arm_left"
                            :class="{ say_hi: animationIs === 'sayHi' }"
                            fill="#282F39"
                            fill-rule="nonzero"
                            d="M106.1 115.3c-25.75,-4.39 -46.83,-14.42 -66.86,-27.78 -4.42,-2.46 -5.02,-4.53 -5.02,-4.53 -0.85,-9.16 -2.94,-19.13 -8.61,-25.92 -1.14,-1.38 -3.89,-0.3 -3.69,1.52 0.52,4.71 2.24,9.05 3.35,13.63 0.52,2.11 0.91,4.24 1.25,6.36 -5.24,-3.91 -10.47,-8.02 -15.74,-12.31 -2.48,-2.01 -5.49,1.01 -3.49,3.49 4.88,6.05 10.48,11.53 16.56,16.54 -7.02,1.27 -14,0.75 -21.71,0.08 -2.2,-0.2 -2.93,3.09 -1.1,4.09 8.69,4.78 19.3,4.43 28.78,1.56 0.62,0.27 1.28,0.43 1.94,0.36 21.47,15.23 47.69,25.01 72.34,30.16 4.76,0.99 6.72,-6.44 2,-7.25z"
                        />
                        <path
                            ref="armRight"
                            class="arm_right"
                            fill="#282F39"
                            fill-rule="nonzero"
                            d="M345.08 98.88c-14.24,-6.65 -33.74,-5.39 -45.14,6.05 -2.09,2.1 0.94,5.73 3.46,4.53 14.53,-6.93 29.15,-12.04 43.96,-1.92 6.36,4.36 11.63,10.87 14.37,18.1 2.34,6.15 1.05,13.02 3.44,18.88 0.1,0.24 0.27,0.45 0.44,0.65 -4.71,6.45 -8.38,17.13 -4.8,24.3 0.98,1.98 3.73,1.63 4.16,-0.55 0.77,-3.84 0.24,-7.71 1.32,-11.57 0.76,-2.71 1.96,-5.11 3.42,-7.41 0.86,2.05 1.84,4.08 2.54,6 1.53,4.12 2.58,8.67 4.97,12.39 0.91,1.42 3.61,0.78 3.64,-0.98 0.06,-4.37 -1.58,-8.74 -2.91,-12.87 -0.72,-2.17 -1.38,-4.71 -2.26,-7.12 1.69,0.76 3.27,1.76 4.91,3.22 3.34,2.98 5.76,6.73 8.85,9.93 1.72,1.79 4.5,0.31 3.76,-2.14 -2.38,-7.95 -14.07,-21.1 -23.55,-17.91 -1.84,-8.63 -0.72,-16.13 -5.88,-24.67 -4.41,-7.31 -10.95,-13.29 -18.7,-16.91z"
                        />
                    </g>
                    <path
                        ref="mouth"
                        class="mouth"
                        fill="#282F39"
                        fill-rule="nonzero"
                        d="M209.1 155.68c-5.97,3.16 -11.62,4.47 -18.33,4.63 -6.09,0.15 -9.96,-2.7 -15.31,-4.88 -2.93,-1.19 -4.49,3.34 -2.06,4.91 6.89,4.47 11.33,6.11 19.69,5.58 6.89,-0.43 13.61,-1.89 19.08,-6.25 2.33,-1.87 -0.64,-5.28 -3.07,-3.99z"
                    />
                </g>
            </g>
        </svg>
    </transition>
</template>
<script>
import { gsap } from "gsap";
export default {
    name: "Tomato",

    props: {
        size: {
            type: Number,
            required: false,
            default: 90,
        },
        animationIs: {
            type: String,
            required: false,
            default: null,
        },
    },

    methods: {
        onEnter() {
            if (!this.animationIs) return;
            if (this.animationIs === "squeeze") {
                this.squeeze();
                return;
            } else if (this.animationIs === "jump") {
                this.jump();
                return;
            } else if (this.animationIs === "sad") {
                this.sad();
                return;
            }
        },

        squeeze() {
            var tl = gsap.timeline({ repeat: -1, repeatDelay: 1 });
            gsap.set(this.$refs.heroBody, { transformStyle: "preserve-3d" });
            tl.to(this.$refs.eyes, {
                y: -60,
                x: -10,
                scale: 1.8,
                duration: 0.5,
                ease: "bounce.out",
                z: 30,
            })
                .to(this.$refs.tomato, { rotationY: 35, duration: 0.1 }, 0)
                .to(this.$refs.arms, { scale: 0.8, x: 5, duration: 0 }, 0)
                .to(
                    this.$refs.eyes,
                    { y: 0, x: 0, scale: 1, duration: 0.3 },
                    0.7
                )
                .to(
                    ".hero",
                    { rotationY: 0, skewX: 0, scaleX: 1, duration: 0.8 },
                    0.7
                )
                .to(this.$refs.arms, { scale: 1, x: 0, duration: 0.1 }, 0.7);
        },
        jump() {
            var tl = gsap.timeline({ repeat: -1, repeatDelay: 4 });
            gsap.set(this.$refs.legLeft, { transformOrigin: "right top" });
            gsap.set(this.$refs.armLeft, {
                transformOrigin: "right",
                rotate: 0,
            });

            tl.to(
                this.$refs.legRight,
                {
                    rotate: 35,
                    duration: 0.3,
                },
                0
            )
                .to(
                    this.$refs.legLeft,
                    {
                        rotate: -25,
                        duration: 0.3,
                    },
                    0
                )
                .to(
                    this.$refs.armLeft,
                    {
                        rotate: -70,
                        y: 20,
                        x: -20,
                        duration: 0.4,
                    },
                    0
                )
                .to(
                    this.$refs.armRight,
                    {
                        rotate: 70,
                        x: 10,
                        duration: 0.4,
                    },
                    0
                )
                .to(
                    this.$refs.tomato,
                    {
                        y: -30,
                        duration: 0.8,
                    },
                    0
                )
                .to(
                    this.$refs.heroBody,
                    {
                        fill: "#4C5A23",
                        duration: 0.3,
                    },
                    0
                )
                .to(
                    this.$refs.brows,
                    {
                        y: -20,
                        duration: 0.4,
                        scaleY: 1.3,
                    },
                    0
                )
                .to(
                    this.$refs.stalk,
                    {
                        y: -30,
                        duration: 0.1,
                    },
                    0.1
                )
                .to(
                    this.$refs.Brows,
                    {
                        y: 0,
                        duration: 0.4,
                        scaleY: 1,
                    },
                    0.7
                )
                .to(
                    this.$refs.legLeft,
                    {
                        rotate: 0,
                        duration: 0.3,
                    },
                    0.7
                )
                .to(
                    this.$refs.legRight,
                    {
                        rotate: 0,
                        duration: 0.3,
                    },
                    0.7
                )
                .to(
                    this.$refs.armLeft,
                    {
                        rotate: 0,
                        x: 0,
                        y: 0,
                        duration: 0.4,
                    },
                    0.7
                )
                .to(
                    this.$refs.armRight,
                    {
                        rotate: 0,
                        x: 0,
                        duration: 0.4,
                    },
                    0.7
                )
                .to(
                    this.$refs.stalk,
                    {
                        y: 0,
                        duration: 0.1,
                    },
                    0.7
                )
                .to(
                    this.$refs.tomato,
                    {
                        y: 0,
                        duration: 0.3,
                    },
                    0.7
                )
                .to(
                    this.$refs.heroBody,
                    {
                        fill: "#EE5B3F",
                        duration: 0.3,
                    },
                    0.7
                );
        },
        sad() {
            var tl = gsap.timeline({ repeat: -1 });
            gsap.set(this.$refs.tomato, { transformStyle: "preserve-3d" });

            gsap.set(this.$refs.mouth, {
                rotate: "180deg",
                translateX: 50,
                translateY: 20,
            });

            gsap.set(this.$refs.armLeft, {
                rotate: "300deg",
                translateY: 150,
                translateX: -5,
            });

            gsap.set(this.$refs.eyeRight, {
                x: 10,
            });

            gsap.set(this.$refs.eyeRightBrow, {
                rotate: 75,
                x: 30,
            });
        },
    },
};
</script>

<style scss scoped>
* {
    overflow: visible;
}

.say_hi {
    transform-origin: bottom right;
    transform: rotate(20deg) translateX(-50px) translateY(100px);
    animation: hi infinite 5s ease-in-out;
}

@keyframes hi {
    0% {
        transform: rotate(20deg) translateX(-50px) translateY(100px);
    }

    5% {
        transform: rotate(40deg) translateX(-50px) translateY(220px);
    }

    10% {
        transform: rotate(20deg) translateX(-50px) translateY(100px);
    }

    90% {
        transform: rotate(20deg) translateX(-50px) translateY(100px);
    }

    95% {
        transform: rotate(40deg) translateX(-50px) translateY(220px);
    }

    100% {
        transform: rotate(20deg) translateX(-50px) translateY(100px);
    }
}
</style>
